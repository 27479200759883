new Vue({
  el: '#slider',
  data() {
    return {
      selected: 1,
      total: 0,
      references: {
        slides: null,
        progress: null,
      },
      delay: 20000,
      progress: {
        total: 0,
        style: '0%',
      }
    }
  },
  methods: {
    updateProgress() {
      if(this.progress.total + 1 <= 100){
        this.progress.total += 1;
        this.progress.style = `width:${this.progress.total}%`
      } else {
        this.progress.total = 0;
        this.progress.style = 'width:0%';
      }    
    },
    updateSlides() {
      if (this.selected + 1 <= this.total){
        this.selected += 1;
      }else{
        this.selected = 1;
      }    
    },
    next() {
      if (this.selected + 1 <= this.total) {
        this.selected += 1;
      }else{
        this.selected = 1;
      }
      clearInterval(this.references.slides);
      clearInterval(this.references.progress);
      this.progress.total = 0;
      this.progress.style = 'width:0%';
      this.references.slides = setInterval(this.updateSlides, this.delay);
      this.references.progress = setInterval(this.updateProgress, this.delay / 100);  
    },
    back() {
      if (this.selected - 1 >= 1) {
        this.selected -= 1;
      }else{
        this.selected = this.total;
      }
      clearInterval(this.references.slides);
      clearInterval(this.references.progress);
      this.progress.total = 0;
      this.progress.style = 'width:0%';
      this.references.slides = setInterval(this.updateSlides, this.delay);
      this.references.progress = setInterval(this.updateProgress, this.delay / 100);      
    }
  },
  created() {
    const slides = document.querySelectorAll('.slide');
    this.total = slides.length;
    this.updateProgress();
    this.references.slides = setInterval(this.updateSlides, this.delay);
    this.references.progress = setInterval(this.updateProgress, this.delay / 100);
  }
});